var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview-edit" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.curFormatMachineType,
            callback: function ($$v) {
              _vm.curFormatMachineType = $$v
            },
            expression: "curFormatMachineType",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "58mm规格", name: "58" } }),
          _c("el-tab-pane", { attrs: { label: "80mm规格", name: "80" } }),
          _c("el-tab-pane", { attrs: { label: "110mm规格", name: "110" } }),
        ],
        1
      ),
      _c(
        "el-scrollbar",
        [
          _c(
            "draggable",
            {
              staticClass: "draggable",
              attrs: {
                chosenClass: "chosen",
                "ghost-class": "ghost",
                group: _vm.groupRight,
                animation: "300",
                id: "cashierTemp",
              },
              model: {
                value: _vm.list,
                callback: function ($$v) {
                  _vm.list = $$v
                },
                expression: "list",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "span",
                {
                  key: item.id,
                  class: { chosen: _vm.activeIndex === index },
                  staticStyle: { display: "inline-block" },
                  style: _vm.handleStyle(item.style),
                  on: {
                    click: function ($event) {
                      return _vm.clickItem(index)
                    },
                  },
                },
                [
                  item.type === "text"
                    ? [
                        _c(
                          "span",
                          {
                            style: _vm.handleStyle(
                              Object.assign({}, item.labelStyle)
                            ),
                          },
                          [_vm._v(_vm._s(item.label))]
                        ),
                        _c("span", [_vm._v(_vm._s(item.value))]),
                      ]
                    : item.type === "goods"
                    ? [
                        _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          _vm._l(
                            item.children.filter(function (x) {
                              return x.show
                            }),
                            function (item2) {
                              return _c(
                                "span",
                                {
                                  key: item2.name,
                                  staticStyle: { display: "inline-block" },
                                  style: _vm.handleStyle(
                                    Object.assign(
                                      {},
                                      item.style,
                                      item2.style,
                                      item.labelStyle
                                    )
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(item2.label))])]
                              )
                            }
                          ),
                          0
                        ),
                        [_vm._v("#item")],
                        _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          _vm._l(
                            item.children.filter(function (x) {
                              return x.show
                            }),
                            function (item2) {
                              return _c(
                                "span",
                                {
                                  key: item2.value,
                                  staticStyle: { display: "inline-block" },
                                  style: _vm.handleStyle(
                                    Object.assign({}, item.style, item2.style)
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(item2.value))])]
                              )
                            }
                          ),
                          0
                        ),
                        [_vm._v("#item")],
                      ]
                    : item.type === "goods2"
                    ? [
                        [_vm._v("#item")],
                        _vm._l(
                          item.children.filter(function (x) {
                            return x.show
                          }),
                          function (item2) {
                            return _c(
                              "div",
                              {
                                key: item2.name,
                                staticStyle: { display: "inline-block" },
                                style: _vm.handleStyle(
                                  Object.assign({}, item.style, item2.style)
                                ),
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style: _vm.handleStyle(
                                      Object.assign({}, item.labelStyle)
                                    ),
                                  },
                                  [_vm._v(_vm._s(item2.label))]
                                ),
                                _c("span", [_vm._v(_vm._s(item2.value))]),
                              ]
                            )
                          }
                        ),
                        [_vm._v("#item")],
                      ]
                    : item.type === "img"
                    ? [
                        !item.value
                          ? _c("span", [_vm._v(_vm._s(item.label))])
                          : _c("img", {
                              attrs: {
                                width: "80px",
                                height: "80px",
                                src: item.value,
                                alt: "加载中",
                              },
                            }),
                      ]
                    : item.type === "solidSplitLine"
                    ? [
                        _c("div", {
                          staticStyle: {
                            "border-bottom": "1px solid #222",
                            "margin-top": "4px",
                            "margin-bottom": "6px",
                          },
                        }),
                      ]
                    : item.type === "dashedSplitLine"
                    ? [
                        _c("div", {
                          staticStyle: {
                            "border-bottom": "1px dashed #222",
                            "margin-top": "4px",
                            "margin-bottom": "6px",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }