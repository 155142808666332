var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("topOperatingButton", {
        attrs: {
          isSubmitAddBtn: false,
          isAuditBillBtn: false,
          isQuitBtn: false,
        },
        on: { submitForm: _vm.submitForm },
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "抹零设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-form",
                      { attrs: { model: _vm.form } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "单价舍入方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "marL15",
                                attrs: {
                                  size: "small",
                                  placeholder: "请选择",
                                  filterable: "",
                                },
                                model: {
                                  value:
                                    _vm.form
                                      .shop_CASHIER_CONFIG_PARAMETERS_ERASE_11,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "shop_CASHIER_CONFIG_PARAMETERS_ERASE_11",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_11",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.round_off_type,
                                function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "x-bc" }, [
                                        _c("span", {}, [
                                          _vm._v(_vm._s(item.label)),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.labelTOW))]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "行金额舍入方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-left": "2px" },
                                attrs: {
                                  size: "small",
                                  placeholder: "请选择",
                                  filterable: "",
                                },
                                model: {
                                  value:
                                    _vm.form
                                      .shop_CASHIER_CONFIG_PARAMETERS_ERASE_12,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "shop_CASHIER_CONFIG_PARAMETERS_ERASE_12",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_12",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.round_off_type,
                                function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "x-bc" }, [
                                        _c("span", {}, [
                                          _vm._v(_vm._s(item.label)),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.labelTOW))]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "收款舍入方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "marL15",
                                attrs: {
                                  size: "small",
                                  placeholder: "请选择",
                                  filterable: "",
                                },
                                model: {
                                  value:
                                    _vm.form
                                      .shop_CASHIER_CONFIG_PARAMETERS_ERASE_13,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "shop_CASHIER_CONFIG_PARAMETERS_ERASE_13",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_13",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.round_off_type,
                                function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "x-bc" }, [
                                        _c("span", {}, [
                                          _vm._v(_vm._s(item.label)),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.labelTOW))]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "最大允许抹零" } },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "230px",
                                "padding-left": "15px",
                              },
                              attrs: {
                                placeholder: "请输入内容",
                                type: "number",
                              },
                              model: {
                                value:
                                  _vm.form
                                    .shop_CASHIER_CONFIG_PARAMETERS_ERASE_14,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "shop_CASHIER_CONFIG_PARAMETERS_ERASE_14",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_14",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "零售设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c(
                    "div",
                    { staticClass: "x-f marB10" },
                    [
                      _c("span", [
                        _vm._v(
                          "收银员交班时启用盲交模式(盲交不显示交班的金额明细,要求输入钱箱中的实际金额)"
                        ),
                      ]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_PARAMETERS_RETAIL_21,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_CONFIG_PARAMETERS_RETAIL_21",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_CASHIER_CONFIG_PARAMETERS_RETAIL_21",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("span", [_vm._v("收银端同一商品加入购物车显示为1行")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_PARAMETERS_SALES_53,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_CONFIG_PARAMETERS_SALES_53",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_CASHIER_CONFIG_PARAMETERS_SALES_53",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "议价商品设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("span", [_vm._v("商品售价为0时默认为议价商品")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value: _vm.form.shop_CASHIER_GOODS_BARGAINING_81,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_GOODS_BARGAINING_81",
                              $$v
                            )
                          },
                          expression: "form.shop_CASHIER_GOODS_BARGAINING_81",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "前台商品排序" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15 y-start" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", [_vm._v("前台商品排序")]),
                        _c("el-tooltip", {
                          staticClass: "item marL20",
                          attrs: {
                            effect: "dark",
                            content: _vm.content1,
                            placement: "top",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT10" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value:
                                _vm.form
                                  .shop_CASHIER_CONFIG_PARAMETERS_GOODS_SORT_31,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_CASHIER_CONFIG_PARAMETERS_GOODS_SORT_31",
                                  $$v
                                )
                              },
                              expression:
                                "form.shop_CASHIER_CONFIG_PARAMETERS_GOODS_SORT_31",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("按编码排序"),
                            ]),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("按价格排序"),
                            ]),
                            _c("el-radio", { attrs: { label: "3" } }, [
                              _vm._v("创建时间(新创建时间)"),
                            ]),
                            _c("el-radio", { attrs: { label: "4" } }, [
                              _vm._v("按商品首字母"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "找零窗口设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c(
                    "div",
                    { staticClass: "marB10" },
                    [
                      _c("span", { staticClass: "padd20" }, [
                        _vm._v("找零窗口停留时间"),
                      ]),
                      _c("el-input", {
                        staticClass: "input marL5 marR5",
                        staticStyle: { width: "60px" },
                        attrs: { size: "mini", type: "number" },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_PARAMETERS_WINDOW_41,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_CONFIG_PARAMETERS_WINDOW_41",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_CASHIER_CONFIG_PARAMETERS_WINDOW_41",
                        },
                      }),
                      _c("span", [_vm._v("毫秒")]),
                      _c("el-tooltip", {
                        staticClass: "item marL20",
                        attrs: {
                          effect: "dark",
                          content: _vm.content1,
                          placement: "top",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", { staticClass: "padd20" }, [
                        _vm._v("允许收银端手工输入会员卡时长"),
                      ]),
                      _c("el-input", {
                        staticClass: "input marL5 marR5",
                        staticStyle: { width: "120px" },
                        attrs: { size: "mini", type: "number" },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_PARAMETERS_WINDOW_42,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_CONFIG_PARAMETERS_WINDOW_42",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_CASHIER_CONFIG_PARAMETERS_WINDOW_42 ",
                        },
                      }),
                      _c("span", [_vm._v("毫秒")]),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "启用导购员设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15 x-f" }, [
                  _c(
                    "div",
                    { staticClass: "x-f marR10" },
                    [
                      _c("span", [_vm._v("充值启用导购员")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_PARAMETERS_SALES_51,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_CONFIG_PARAMETERS_SALES_51",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_CASHIER_CONFIG_PARAMETERS_SALES_51",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("span", [_vm._v("销售启用导购员")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_PARAMETERS_SALES_52,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_CONFIG_PARAMETERS_SALES_52",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_CASHIER_CONFIG_PARAMETERS_SALES_52",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "商品畅滞销统计设置(参数调整后次日生效)" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c("div", { staticClass: "x-f" }, [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("统计周期")]),
                        _c("el-input", {
                          staticClass: "inputWidth underline-input",
                          attrs: { size: "mini", type: "number" },
                          model: {
                            value:
                              _vm.form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_61,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_CASHIER_CONFIG_GOODS_SALE_COUNT_61",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_61",
                          },
                        }),
                        _c("span", [_vm._v("天")]),
                        _c("span", { staticClass: "marR5" }, [
                          _vm._v("定时计算时间,每天:"),
                        ]),
                        _c("el-time-picker", {
                          attrs: { size: "mini" },
                          model: {
                            value:
                              _vm.form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_62,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_CASHIER_CONFIG_GOODS_SALE_COUNT_62",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_62",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("畅销:销售额前占比")]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input",
                        attrs: { size: "mini", type: "number" },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_63,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_CONFIG_GOODS_SALE_COUNT_63",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_63",
                        },
                      }),
                      _c("span", [_vm._v("%")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("滞销:销售额后占比")]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input",
                        attrs: { size: "mini", type: "number" },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_64,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_CASHIER_CONFIG_GOODS_SALE_COUNT_64",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_64",
                        },
                      }),
                      _c("span", [_vm._v("%")]),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "多仓库设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "padd10",
                          model: {
                            value: _vm.form.shop_CASHIER_CONFIG_WAREHOUSES_71,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_CASHIER_CONFIG_WAREHOUSES_71",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_CASHIER_CONFIG_WAREHOUSES_71",
                          },
                        },
                        [_vm._v("启用多仓库(仅适用门店POS开单指定多仓库)")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "条码秤条码参数设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c(
                    "div",
                    { staticClass: "x-f marB20" },
                    [
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("条码标识"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input",
                        attrs: {
                          size: "mini",
                          type: "number",
                          placeholder: "请输入条码标识",
                        },
                        model: {
                          value:
                            _vm.form.shop_CASHIER_CONFIG_BARCODE_91
                              .barcodeIdentification,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.shop_CASHIER_CONFIG_BARCODE_91,
                              "barcodeIdentification",
                              $$v
                            )
                          },
                          expression:
                            "\n              form.shop_CASHIER_CONFIG_BARCODE_91.barcodeIdentification\n            ",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marB20" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.form.shop_CASHIER_CONFIG_BARCODE_91.yardage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.shop_CASHIER_CONFIG_BARCODE_91,
                                "yardage",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_CASHIER_CONFIG_BARCODE_91.yardage",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("13码"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("18码"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB20" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "marB20",
                          model: {
                            value:
                              _vm.form.shop_CASHIER_CONFIG_BARCODE_91
                                .barcodeValue,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.shop_CASHIER_CONFIG_BARCODE_91,
                                "barcodeValue",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_CASHIER_CONFIG_BARCODE_91.barcodeValue",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(
                              _vm._s(
                                _vm.form.shop_CASHIER_CONFIG_BARCODE_91
                                  .yardage == 1
                                  ? "取金额（常用）"
                                  : "取“重量+金额”（常用）"
                              )
                            ),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(
                              _vm._s(
                                _vm.form.shop_CASHIER_CONFIG_BARCODE_91
                                  .yardage == 1
                                  ? "取重量"
                                  : "取“重量+单价”"
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.form.shop_CASHIER_CONFIG_BARCODE_91.yardage == 1
                        ? _c("div", [
                            _vm._v(
                              " 条码格式：标识位2位或1位+货号5位或6位+金额或重量5位+校验位1位 "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              " 条码格式：标识位2位或1位+货号5位或6位+重量5位+单价或金额5位+校验位1位 "
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marB20" },
                    [
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("条码金额精度"),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.form.shop_CASHIER_CONFIG_BARCODE_91
                                .amountAccuracy,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.shop_CASHIER_CONFIG_BARCODE_91,
                                "amountAccuracy",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_CASHIER_CONFIG_BARCODE_91.amountAccuracy",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("0"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("1"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("2"),
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("3"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marB20" },
                    [
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("条码重量精度"),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.form.shop_CASHIER_CONFIG_BARCODE_91
                                .weightAccuracy,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.shop_CASHIER_CONFIG_BARCODE_91,
                                "weightAccuracy",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_CASHIER_CONFIG_BARCODE_91.weightAccuracy",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("0"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("1"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("2"),
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("3"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("单价重量精度"),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.form.shop_CASHIER_CONFIG_BARCODE_91
                                .priceAccuracy,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.shop_CASHIER_CONFIG_BARCODE_91,
                                "priceAccuracy",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_CASHIER_CONFIG_BARCODE_91.priceAccuracy",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("0"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("1"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("2"),
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("3"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }