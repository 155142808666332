<template>
  <div class="wrap">
    <topOperatingButton :isSubmitAddBtn="false" :isAuditBillBtn="false" :isQuitBtn="false" @submitForm="submitForm">
    </topOperatingButton>
    <cardTitleCom cardTitle="会员密码设置" class="bg-Color marT10">
      <template #cardContent>
        <div class="marT15">
          <span class="padd15">当会员没有设置密码时,若您希望启用系统默认的缺省密码,请勾选</span>
          <div class="x-f">
            <el-checkbox v-model="form.shop_VIP_CONFIG_PASSWORD_11" class="padd15" true-label="Y"
              false-label="N">启用缺省密码</el-checkbox>
            <!-- <el-tooltip
              class="item marL20"
              effect="dark"
              :content="content2"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip> -->
            <div class="marL20" v-if="form.shop_VIP_CONFIG_PASSWORD_11 == 'Y'">
              <span class="marR5">缺省密码</span>
              <el-input size="mini" type="number" class="inputWidth underline-input"
                v-model="form.shop_VIP_CONFIG_PASSWORD_14"></el-input>
            </div>
          </div>
        </div>
        <div class="marT5 marB15 x-f">
          <div>
            <span class="padd15">会员启用消费密码</span>
            <el-switch class="marL20" active-value="Y" inactive-value="N" v-model="form.shop_VIP_CONFIG_PASSWORD_12">
            </el-switch>
          </div>

          <div class="marL20" v-if="form.shop_VIP_CONFIG_PASSWORD_12 == 'Y'">
            <span class="marR5">初始密码</span>
            <el-input size="mini" type="number" class="inputWidth underline-input"
              v-model="form.shop_VIP_CONFIG_PASSWORD_15"></el-input>
          </div>
        </div>
        <div class="marL15 marB15">
          <span>开启储值卡免密支付，免密额度</span>
          <el-input size="mini" type="number" class="inputWidth underline-input padd5"
            v-model="form.shop_VIP_CONFIG_PASSWORD_13"></el-input>
          <span>元</span>
          <div class="padd15 red-font">
            说明:开启免密支付，储值卡单笔付款金额超过免密额度需要输入支付密码，会员未设置密码时不受免密开关限制，支付无需输入密码
          </div>
          <div class="marT15 marL10">
            <el-checkbox v-model="form.shop_VIP_CONFIG_STORED_31" class="marL10 marB10" true-label="Y"
              false-label="N">允许前台不打印会员明码信息</el-checkbox>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="IC卡设置" class="bg-Color marT10">
      <template #cardContent>
        <div class="marT15 marL10">
          <el-checkbox v-model="form.shop_VIP_CONFIG_IC_CARD_71" class="marL10 marB10">设置IC卡</el-checkbox>
          <div class="x-f marB10">
            <div class="marL10" style="width: 100px">IC卡类型</div>
            <el-select :disabled="!form.shop_VIP_CONFIG_IC_CARD_71" class="inputWidth" size="mini"
              v-model="form.shop_VIP_CONFIG_IC_CARD_72" placeholder="请选择IC卡类型" @change="cardTypeChange">
              <el-option label="默认版" value="1"></el-option>
              <el-option label="定制版" value="2"></el-option>
            </el-select>
          </div>
          <div class="x-f marB10">
            <div class="marL10" style="width: 100px">IC卡读写密码</div>
            <el-input :disabled="!form.shop_VIP_CONFIG_IC_CARD_71" size="mini" maxlength="12" class="inputWidth"
              show-password ref="myInput" v-model="form.shop_VIP_CONFIG_IC_CARD_73"></el-input>
          </div>
          <div class="x-f marB10 blockNumber">
            <div class="marL10" style="width: 100px">扇区</div>
            <el-input :disabled="
                form.shop_VIP_CONFIG_IC_CARD_72 == '1' ||
                !form.shop_VIP_CONFIG_IC_CARD_71
              " size="mini" maxlength="12" class="inputWidth" show-password ref="myInput"
              v-model="form.shop_VIP_CONFIG_IC_CARD_74"></el-input>
          </div>
          <div class="x-f marB10 blockNumber">
            <div class="marL10" style="width: 100px">块号</div>
            <el-input :disabled="
                form.shop_VIP_CONFIG_IC_CARD_72 == '1' ||
                !form.shop_VIP_CONFIG_IC_CARD_71
              " size="mini" maxlength="12" class="inputWidth" show-password
              v-model="form.shop_VIP_CONFIG_IC_CARD_75"></el-input>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="储值设置" class="bg-Color marT10">
      <template #cardContent>
        <div class="marT15 marL10">
          <div class="y-start">
            <el-checkbox v-model="form.shop_VIP_CONFIG_STORED_21" true-label="Y" false-label=""
              class="padd10">支持储值卡多卡付款</el-checkbox>
            <el-checkbox v-model="form.shop_VIP_CONFIG_STORED_22" class="padd10" true-label="Y"
              false-label="">充值卡充值时允许为负数</el-checkbox>
            <el-checkbox v-model="form.shop_VIP_CONFIG_STORED_23" class="padd10" true-label="Y"
              false-label="">是否允许手工赠送(非储值赠送规则外)</el-checkbox>
          </div>
          <span class="marL10">会员的储值卡余额低于</span>
          <el-input size="mini" type="number" class="inputWidth underline-input padd5"
            v-model="form.shop_VIP_CONFIG_STORED_24"></el-input>
          <span>元，不享受会员优惠</span>
          <span class="red-font">(注: 金额为空或未输入金额时不控制)</span>
        </div>
        <div class="marB10 marL10">
          <div class="y-start">
            <el-checkbox v-model="form.shop_VIP_CONFIG_STORED_25" class="padd10" true-label="Y"
              false-label="">非会员余额支付不享受优惠</el-checkbox>
            <el-checkbox v-model="form.shop_VIP_CONFIG_STORED_26" true-label="Y" false-label=""
              class="marL10 marT5">是否允许前台充值</el-checkbox>
          </div>
        </div>

        <div class="marB15">
          <div class="marL20">
            <span>最大金额</span>
            <el-input size="mini" type="number" class="inputWidth underline-input padd5"
              v-model="form.shop_VIP_CONFIG_STORED_27" :disabled="!checked"></el-input>
            <span>充值最小倍数金额</span>
            <el-input size="mini" type="number" class="inputWidth underline-input padd5"
              v-model="form.shop_VIP_CONFIG_STORED_28" :disabled="!checked"></el-input>
          </div>
        </div>
        <div class="marL20 marB10">
          <span>启用优惠券消费的金额积分</span>
          <el-switch class="marL20" v-model="form.shop_VIP_CONFIG_STORED_29" active-value="Y" inactive-value="N">
          </el-switch>
        </div>
        <div class="marL20 marB10">
          <span>会员消费限制</span>
          <el-switch class="marL20" v-model="form.shop_VIP_CONFIG_STORED_30" active-value="Y" inactive-value="N">
          </el-switch>
        </div>
        <div class="marL20">
          <span>启用会员卡储零功能</span>
          <el-switch class="marL20" v-model="form.shop_VIP_CONFIG_STORED_32" active-value="Y" inactive-value="N">
          </el-switch>
        </div>
        <div>
          <div class="marT15 marL20">会员卡充值赠送金额控制:</div>
          <span class="marL20 marT5">赠送金额不得高于当次充值金额的</span>
          <el-input size="mini" type="number" class="inputWidth underline-input padd5"
            v-model="form.shop_VIP_CONFIG_STORED_210"></el-input>
          <span>%</span>
        </div>
        <div>
          <div class="marT10 marL10 marB5">
            <span class="marL10">若您需要启用赊账业务,请勾选</span>
            <div class="x-f">
              <el-checkbox v-model="form.shop_VIP_CONFIG_STORED_211" class="marL10" true-label="1"
                false-label="">启用赊账业务</el-checkbox>
              <!-- <el-tooltip
                class="item marL10"
                effect="dark"
                :content="content2"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip> -->
              <div class="marL30">
                <span>单个用户最高授信额度</span>
                <el-input size="mini" type="number" class="inputWidth underline-input padd5"
                  v-model="form.shop_VIP_CONFIG_STORED_212"></el-input>
              </div>
            </div>
          </div>
          <div class="marL20 marB10">
            <span>会员价/会员折扣支持临时改价/折扣折上折</span>
            <el-switch class="marL20" v-model="form.shop_VIP_CONFIG_STORED_33" active-value="Y" inactive-value="N">
            </el-switch>
          </div>
           <div class="marL20 marB10">
            <span>充值小票打印付款留底单</span>
            <el-switch class="marL20" v-model="form.shop_VIP_CONFIG_STORED_35" active-value="Y" inactive-value="N">
            </el-switch>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- <cardTitleCom cardTitle="非会员余额设置" class="bg-Color marT10">
      <template #cardContent> </template>
    </cardTitleCom> -->
    <cardTitleCom cardTitle="清除会员卡积分" class="bg-Color marT10 marB10">
      <template #cardContent>
        <div class="marT15 marB15 padd15">
          <el-button type="primary" size="mini" @click="open">立即清除</el-button>
          <el-checkbox class="marL30" v-model="form.shop_VIP_CONFIG_INTEGRAL_CLEAN_42">定时清除
          </el-checkbox>

          <el-date-picker v-model="dateTimeValue" v-if="form.shop_VIP_CONFIG_INTEGRAL_CLEAN_42" type="date" size="mini"
            class="marL10" @change="handleDateTimeChange" value-format="yyyy-MM-dd"></el-date-picker>

          <el-dialog title="请输入设置密码" :visible.sync="showDialog" width="30%">
            <el-input placeholder="请输入内容" v-model="password" autocomplete="new-password" type="password"
              class="input-with-select" show-password>
            </el-input>
            <span slot="footer" class="dialog-footer">
              <el-button @click="cancelPasswordInput">取消</el-button>
              <el-button type="primary" @click="confirmPasswordInput">确定</el-button>
            </span>
          </el-dialog>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="会员卡提醒设置" class="bg-Color marT10 marB10">
      <template #cardContent>
        <div class="marB15 marL15 marT10">
          <div>
            <el-checkbox v-model="form.shop_VIP_CONFIG_REMIND_51.ruleType" true-label="1"
              false-label="">会员卡生日提前</el-checkbox>
            <el-input style="width: 60px" size="mini" type="number" class="input marL5 marR5"
              v-model="form.shop_VIP_CONFIG_REMIND_51.ruleValue" :min="1"
              :disabled="form.shop_VIP_CONFIG_REMIND_51.ruleType != '1'"></el-input>

            天提醒
          </div>
          <div>
            <el-checkbox v-model="form.shop_VIP_CONFIG_REMIND_52.ruleType" :checked="isCheckboxChecked2" true-label="1"
              false-label="">会员卡到期提前</el-checkbox><el-input style="width: 60px" size="mini" type="number"
              class="input marT5 marL5 marR5" v-model="form.shop_VIP_CONFIG_REMIND_52.ruleValue" :min="1"
              :disabled="form.shop_VIP_CONFIG_REMIND_52.ruleType != '1'">
            </el-input>
            天提醒
          </div>
          <div>
            <el-checkbox v-model="form.shop_VIP_REMIND_53.ruleType" true-label="1" false-label=""
              :checked="isCheckboxChecked3">会员卡余额不足</el-checkbox><el-input style="width: 60px" size="mini" type="number"
              class="input marT5 marL5 marR5" v-model="form.shop_VIP_REMIND_53.ruleValue" :min="1"
              :disabled="form.shop_VIP_REMIND_53.ruleType != '1'"></el-input>元提醒
          </div>

          <div class="marT10">会员卡异常设置</div>
          <div>
            <el-checkbox true-label="1" false-label="" v-model="isCheckboxChecked4" :checked="isVal">
              <el-select v-model="form.shop_VIP_CONFIG_ERROR_61" placeholder="每天" style="width: 80px" size="mini"
                :disabled="!isCheckboxChecked4">
                <el-option label="每天" value="1"></el-option>
                <el-option label="每月" value="3"></el-option>
              </el-select>
              会员卡消费次数超过
              <el-input :disabled="!isCheckboxChecked4" style="width: 60px" size="mini" :min="1" type="number"
                class="input marT5 marR5" v-model="form.shop_VIP_CONFIG_ERROR_62"></el-input>次
            </el-checkbox>
          </div>
          <div>
            <el-checkbox v-model="isCheckboxChecked5" true-label="1" false-label="">
              <el-select v-model="form.shop_VIP_CONFIG_ERROR_63" placeholder="每天" style="width: 80px" size="mini"
                :disabled="!isCheckboxChecked5">
                <el-option label="每天" value="1"></el-option>
                <el-option label="每月" value="3"></el-option>
              </el-select>
              会员卡消费金额超过
              <el-input :disabled="!isCheckboxChecked5" style="width: 60px" type="number" size="mini"
                class="input marT5 marR5" v-model="form.shop_VIP_CONFIG_ERROR_64"></el-input>元
            </el-checkbox>
          </div>
          <div>
            <el-checkbox v-model="isCheckboxChecked6" true-label="1" false-label="">
              <el-select :disabled="!isCheckboxChecked6" v-model="form.shop_VIP_CONFIG_ERROR_65" placeholder="每天"
                style="width: 80px" size="mini">
                <el-option label="每天" value="1"></el-option>
                <el-option label="每月" value="3"></el-option>
              </el-select>
              会员卡积分次数超过
              <el-input style="width: 60px" type="number" size="mini" class="input marT5 marR5"
                v-model="form.shop_VIP_CONFIG_ERROR_66" :disabled="!isCheckboxChecked6"></el-input>次
            </el-checkbox>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题

import {
  vipParamListAPI,
  saveOrUpdateVipAPI,
  resettingVIPAPI,
} from "@/api/shop/setup/posParam/vipParam";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
export default {
  name: "vipParam",
  components: {
    cardTitleCom,
    topOperatingButton,
  },
  data() {
    return {
      isBirther: false,
      isChecked: false, //会员卡提醒
      isChecked1: false,
      isChecked2: false,
      isUnusual: false, //会员卡异常提醒
      isUnusual1: false,
      isUnusual2: false,
      isUnusual3: false,
      dateTimeValue: "", // 时间选择器的值
      receivingTime: 0,
      content2:
        "按重量（相同模板计算累加: 相同运费模板的商品进行重里累加计算运费后，合计作为单据运费。 单个商品计算累加:每单商品单独计算运费后，合计作为单据运费。）",
      checked: true,
      input: "",
      isCheckboxChecked4: false, // 会员卡消费次数的复选框是否被选中
      isCheckboxChecked5: false, // 会员卡消费金额复选框是否被选中
      isCheckboxChecked6: false, // 会员卡积分次数复选框是否被选中

      form: {
        shop_VIP_CONFIG_STORED_35:'Y',
        shop_VIP_CONFIG_ERROR_61: "1",
        shop_VIP_CONFIG_ERROR_62: "",
        shop_VIP_CONFIG_ERROR_63: "1",
        shop_VIP_CONFIG_ERROR_64: null,
        shop_VIP_CONFIG_ERROR_65: "1",
        shop_VIP_CONFIG_ERROR_66: null,
        shop_VIP_CONFIG_INTEGRAL_CLEAN_41: "",
        shop_VIP_CONFIG_INTEGRAL_CLEAN_42: false,
        shop_VIP_CONFIG_PASSWORD_11: "",
        shop_VIP_CONFIG_PASSWORD_12: false,
        shop_VIP_CONFIG_PASSWORD_13: "",
        shop_VIP_CONFIG_REMIND_51: {
          ruleType: "false",
          ruleValue: "",
        },
        shop_VIP_CONFIG_REMIND_52: {
          ruleType: "false",
          ruleValue: "",
        },
        shop_VIP_CONFIG_STORED_21: true,
        shop_VIP_CONFIG_STORED_210: "",
        shop_VIP_CONFIG_STORED_211: "",
        shop_VIP_CONFIG_STORED_212: "",
        shop_VIP_CONFIG_STORED_22: true,
        shop_VIP_CONFIG_STORED_23: true,
        shop_VIP_CONFIG_STORED_24: "",
        shop_VIP_CONFIG_STORED_25: "",
        shop_VIP_CONFIG_STORED_26: true,
        shop_VIP_CONFIG_STORED_27: "",
        shop_VIP_CONFIG_STORED_28: "",
        shop_VIP_CONFIG_STORED_29: false,
        shop_VIP_REMIND_53: {
          ruleType: "false",
          ruleValue: "",
        },
        shop_VIP_CONFIG_IC_CARD_72: "1",
        shop_VIP_CONFIG_IC_CARD_73: "FFFFFFFFFFFF",
        shop_VIP_CONFIG_IC_CARD_74: "0",
        shop_VIP_CONFIG_IC_CARD_75: "2",
        shop_VIP_CONFIG_STORED_33: 'N'
      },
      radioValue: 0,

      showDialog: false,

      password: "",
      birthdayReminder: "", //生日提醒
      expiryReminder: "", //会员卡到期提醒
      selectedDays: [], //会员卡异常提醒
      selectedFrequency: "",
      maxConsume: "",
      selectedOption: "", // 用于会员卡异常下拉框保存选中的选项值
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    cardTypeChange(shop_VIP_CONFIG_IC_CARD_72) {
      if (shop_VIP_CONFIG_IC_CARD_72 == "0") {
        this.form.shop_VIP_CONFIG_IC_CARD_74 = "0";
        this.form.shop_VIP_CONFIG_IC_CARD_75 = "2";
      }
    },
    getDetails() {
      vipParamListAPI().then((res) => {
        // console.log("售后：", res);
        //将data 对象中的空属性值替换为 this.form 对象中对应属性的值
        Object.keys(res.data).map((key) => {
          if (!res.data[key]) {
            res.data[key] = this.form[key];
          }
          // if (res.data.shop_VIP_CONFIG_REMIND_51.ruleValue) {
          //   this.isBirther = true;
          // }
          if (res.data.shop_VIP_CONFIG_ERROR_62) {
            this.isCheckboxChecked4 = true;
          }
          if (res.data.shop_VIP_CONFIG_ERROR_64) {
            this.isCheckboxChecked5 = true;
          }
          if (res.data.shop_VIP_CONFIG_ERROR_66) {
            this.isCheckboxChecked6 = true;
          }
          // if (res.data.shop_VIP_CONFIG_INTEGRAL_CLEAN_41) {
          //   this.form.shop_VIP_CONFIG_INTEGRAL_CLEAN_42 = true;
          // }
          if (res.data.shop_VIP_CONFIG_INTEGRAL_CLEAN_42 != true) {
            res.data.shop_VIP_CONFIG_INTEGRAL_CLEAN_42 = false;
          } else {
            res.data.shop_VIP_CONFIG_INTEGRAL_CLEAN_42 = true;
          }
        });
        // 使用 this.$set 更新数据
        this.$set(
          this,
          "dateTimeValue",
          res.data.shop_VIP_CONFIG_INTEGRAL_CLEAN_41
        );
        this.form = res.data;
      });
    },

    submitForm() {
      // 获取时间选择器的值
      const selectedDateTime = this.dateTimeValue;

      // 构建请求参数对象，包括时间选择器的值和表单数据
      const requestData = {
        shop_VIP_CONFIG_INTEGRAL_CLEAN_41: selectedDateTime,
        // 将表单数据整个包含在 requestData 中
        ...this.form,
      };

      // 调用 saveOrUpdateVipAPI 函数，将 requestData 作为请求参数传递给后端
      saveOrUpdateVipAPI(requestData)
        .then((res) => {
          // 处理后端响应
          console.log("后端响应:", res);
          this.$message.success(res.msg);
          this.getDetails();
        })
        .catch((error) => {
          // 处理请求失败情况
          console.error("请求失败:", error);
        });
    },

    clearVIP(password) {
      resettingVIPAPI({password:this.password})
        .then((res) => {
          console.log("this.password", this.password);
          // 在请求成功时显示提示消息
          Message.success("积分已成功清除！");
        })
        .catch((error) => {
          console.error("清除积分请求失败：", error);
          // 在请求失败时显示错误消息
          Message.error("积分清除失败，请重试或联系管理员。");
        });
    },
    //清除会员卡积分确定的弹窗
    open() {
      this.$confirm("此操作将永久清除会员卡积分, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.password = "";
        this.showDialog = true; // 确认按钮点击后显示对话框
      });
    },
    //定时请除逻辑
    handleDateTimeChange() {
      this.password = "";
      this.showDialog = true;
    },
    cancelPasswordInput() {
      this.showDialog = false;
      this.password = "";
    },
    confirmPasswordInput() {
      // 在这里处理输入密码后的逻辑
      console.log("输入的密码为: ", this.password);
      this.showDialog = false;

      this.clearVIP();
    },
    handleDateTimeChange(value) {
      // 处理时间选择器的值变化
      console.log("选择的时间：", value);
    },
  },
  computed: {
    //有值就勾选会员卡提醒
    isCheckboxChecked() {
      return (
        this.form.shop_VIP_CONFIG_REMIND_51.ruleValue !== "" &&
        this.form.shop_VIP_CONFIG_REMIND_51.ruleValue !== null
      );
    },
    isCheckboxChecked2() {
      return (
        this.form.shop_VIP_CONFIG_REMIND_52.ruleValue !== "" &&
        this.form.shop_VIP_CONFIG_REMIND_52.ruleValue !== null
      );
    },
    isCheckboxChecked3() {
      return (
        this.form.shop_VIP_REMIND_53.ruleValue !== null &&
        this.form.shop_VIP_REMIND_53.ruleValue !== ""
      );
    },
    isVal() {
      return (
        /*  this.form.shop_VIP_CONFIG_ERROR_62 !== null && */
        this.form.shop_VIP_CONFIG_ERROR_62 !== ""
      );
    },
  },
  watch: {
    isCheckboxChecked4(newVal) {
      if (!newVal) {
        // 如果复选框被取消选中
        this.form.shop_VIP_CONFIG_ERROR_61 = ""; // 清空下拉框的值
        this.form.shop_VIP_CONFIG_ERROR_62 = ""; // 清空输入框的值
      }
    },
    isCheckboxChecked5(newVal) {
      if (!newVal) {
        // 如果复选框被取消选中
        this.form.shop_VIP_CONFIG_ERROR_63 = ""; // 清空下拉框的值
        this.form.shop_VIP_CONFIG_ERROR_64 = ""; // 清空输入框的值
      }
    },
    isCheckboxChecked6(newVal) {
      if (!newVal) {
        // 如果复选框被取消选中
        this.form.shop_VIP_CONFIG_ERROR_65 = ""; // 清空下拉框的值
        this.form.shop_VIP_CONFIG_ERROR_66 = ""; // 清空输入框的值
      }
    },
  },
};
</script>

<style scoped lang="scss">
.blockNumber {
  ::v-deep .el-input__suffix {
    display: none !important;
  }
}

.wrap {
  padding-top: 20px;
}
/* 字体颜色 */
.red-font {
  color: red;
}
.el-icon-view-off {
  color: #ccc;
  cursor: pointer;
}
/* 解决el-input设置类型为number时，中文输入法光标上移问题 */
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/* 解决el-input设置类型为number时，去掉输入框后面上下箭头 */
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
