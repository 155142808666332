<template>
  <!-- 中间预览编辑小票区域 -->
  <div class="preview-edit">
    <el-tabs v-model="curFormatMachineType" type="card">
      <el-tab-pane label="58mm规格" name="58"></el-tab-pane>
      <el-tab-pane label="80mm规格" name="80"></el-tab-pane>
      <el-tab-pane label="110mm规格" name="110"></el-tab-pane>
    </el-tabs>
    <el-scrollbar>
      <draggable
        chosenClass="chosen"
        ghost-class="ghost"
        v-model="list"
        :group="groupRight"
        animation="300"
        class="draggable"
        id="cashierTemp"
      >
        <span
          v-for="(item, index) in list"
          :key="item.id"
          style="display: inline-block"
          :style="handleStyle(item.style)"
          :class="{ chosen: activeIndex === index }"
          @click="clickItem(index)"
        >
          <template v-if="item.type === 'text'">
            <span :style="handleStyle({ ...item.labelStyle })">{{ item.label }}</span>
            <span>{{ item.value }}</span>
          </template>
          <template v-else-if="item.type === 'goods'" style="width: 100%">
            <div style="width: 100%">
              <span
                v-for="item2 in item.children.filter((x) => x.show)"
                :key="item2.name"
                style="display: inline-block"
                :style="
                  handleStyle({ ...item.style, ...item2.style, ...item.labelStyle })
                "
              >
                <span>{{ item2.label }}</span>
              </span>
            </div>
            <template v-show="false">#item</template>
            <div style="width: 100%">
              <span
                v-for="item2 in item.children.filter((x) => x.show)"
                :key="item2.value"
                style="display: inline-block"
                :style="handleStyle({ ...item.style, ...item2.style })"
              >
                <span>{{ item2.value }}</span>
              </span>
            </div>
            <template v-show="false">#item</template>
          </template>
          <template v-else-if="item.type === 'goods2'" style="width: 100%">
            <template v-show="false">#item</template>
            <div
              style="display: inline-block"
              v-for="item2 in item.children.filter((x) => x.show)"
              :key="item2.name"
              :style="handleStyle({ ...item.style, ...item2.style })"
            >
              <span :style="handleStyle({ ...item.labelStyle })">{{ item2.label }}</span>
              <span>{{ item2.value }}</span>
            </div>
            <template v-show="false">#item</template>
          </template>
          <template v-else-if="item.type === 'img'">
            <span v-if="!item.value">{{ item.label }}</span>
            <img v-else width="80px" height="80px" :src="item.value" alt="加载中" />
          </template>
          <template v-else-if="item.type === 'solidSplitLine'">
            <div
              style="border-bottom: 1px solid #222; margin-top: 4px; margin-bottom: 6px"
            ></div>
          </template>
          <template v-else-if="item.type === 'dashedSplitLine'">
            <div
              style="border-bottom: 1px dashed #222; margin-top: 4px; margin-bottom: 6px"
            ></div>
          </template>
        </span>
      </draggable>
    </el-scrollbar>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";
export default {
  name: "PreviewEditHighLevel",
  components: {
    draggable,
  },
  model: { prop: "model", event: "Device" },
  props: {
    formatMachineType: {
      type: Number,
      default: 58,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    model: Array,
  },
  data() {
    return {
      groupRight: {
        name: "itxst",
        pull: false, // 拖出
        put: true, // 拖入
      },
    };
  },
  computed: {
    list: {
      get() {
        return this.model;
      },
      set(e) {
        this.$emit("Device", e);
      },
    },
    curFormatMachineType: {
      get() {
        return String(this.formatMachineType);
      },
      set(e) {
        this.$emit("update:formatMachineType", Number(e));
      },
    },
  },
  methods: {
    // 点击字段
    clickItem(index) {
      this.$emit("update:activeIndex", index);
    },
    //处理样式
    handleStyle(style) {
      return {
        ...style,
        fontSize: style.fontSize + "px",
        lineHeight: style.lineHeight + "px",
        width: style.width + "%",
        paddingLeft: style.paddingLeft + "px",
        paddingRight: style.paddingRight + "px",
        paddingTop: style.paddingTop + "px",
        paddingBottom: style.paddingBottom + "px",
        marginLeft: style.marginLeft + "px",
        marginRight: style.marginRight + "px",
        marginTop: style.marginTop + "px",
        marginBottom: style.marginBottom + "px",
      };
    },
    // 获取当前模板
    getCurTep() {
      let html =
        '<div style="width: 100%">' +
        document
          .getElementById("cashierTemp")
          .innerHTML.replace(new RegExp(`class="chosen"`, "g"), "") +
        "</div>";
      this.$emit("setReceiptFormat", {
        formatMachineType: Number(this.formatMachineType),
        formatContent: html,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/*选中样式*/
.chosen {
  background-color: #edf4ff;
  border: 1px solid #1890ff;
}
// 该样式写进封装的拖拽组件内是不生效的，要在全局样式文件中写入才行
.ghost p {
  background-color: #edf4ff;
  border: 1px solid #1890ff;
}
.preview-edit {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  //滚动条
  .el-scrollbar {
    height: calc(100% - 50px);
    width: 418px;
    overflow: hidden;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__view {
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .draggable {
    width: 100%;
    background-color: #fff;
    padding: 20px;
  }
  .splitLine {
    display: inline-block;
    width: 100%;
    border-bottom: 1px dashed #222;
  }
  .content {
    margin: 0 20px;
    height: 100%;
    background-color: #fff;
    font-size: 13px;
    .edit-header {
      height: 52px;
      background-color: #fff;
      padding: 32px 24px 0 !important;
    }
    .edit-textarea {
      background-color: #fff;
      min-height: 85px;
      ::v-deep .el-textarea__inner {
        overflow: hidden;
        padding: 32px 24px !important;
        border: none;
        resize: none;
        color: #000 !important;
        font-size: 14px;
      }
    }
    .edit-footer {
      height: 52px;
      background-color: #fff;
      padding: 0 24px 32px !important;
    }
    &.w58 {
      width: 272px;
    }
    &.w80 {
      width: 384px;
    }
    &.w110 {
      width: 496px;
    }
  }
}
::v-deep .el-tabs {
  background-color: #fff;
  height: 40px;
  margin-bottom: 10px;
}
</style>
